.box {
  border: 10px solid transparent;
  background: linear-gradient(45deg, var(--primary-bright), var(--primary)) border-box;
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.hexagone::before {
  content: "";
  display: block;
  padding-top: 100%;
  background: currentColor;
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
